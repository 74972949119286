<template>
    <section>
        Nothing to see here
    </section>
</template>

<script lang='ts'>
import { defineComponent } from '@vue/runtime-core';
import store from '@/store';

export default defineComponent({
    beforeRouteEnter(to, from, next){
        if(store.state.firebase?.user)
            next('/admin');
        else
            next();
    }
});
</script>

<style lang="scss" scoped>
section {
    margin-top: 0;
}
</style>