
import { defineComponent } from '@vue/runtime-core';
import store from '@/store';

export default defineComponent({
    beforeRouteEnter(to, from, next){
        if(store.state.firebase?.user)
            next('/admin');
        else
            next();
    }
});
